import {
    ShopifyCartResponse,
    cartBuyerIdentityUpdate,
    cartCreate,
    cartDiscountCodesUpdate,
    cartGet,
    cartLinesAdd,
    cartLinesRemove,
    cartLinesUpdate,
} from '@/shopify-client';
import { localStorageCartIdKey } from '@/shopify-client/localTokenKeys';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { Cart, CartBuyerIdentityInput, CartLineInput, CartLineUpdateInput, ID } from 'shopify-buy';

type ContextType = {
    loading: boolean;
    cart: Cart | null;
    cartItemsAdd: (lines: CartLineInput[]) => Promise<ShopifyCartResponse>;
    cartItemsUpdate: (lines: CartLineUpdateInput[]) => Promise<ShopifyCartResponse>;
    cartItemsRemove: (lines: string[]) => Promise<ShopifyCartResponse>;
    cartAssociateBuyer: (buyerIdentity: CartBuyerIdentityInput) => Promise<ShopifyCartResponse>;
    cartDiscountUpdate: (code: string, remove?: boolean) => Promise<ShopifyCartResponse>;
    addedToCart: boolean;
};

export const ShopifyContext = createContext<ContextType>({} as ContextType);

const isBrowser = typeof window !== `undefined`;

export const ShopifyProvider = ({ children }: { children: ReactNode }) => {
    const [cart, setCart] = useState<Cart | null>(null);
    const [loading, setLoading] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);

    const setCartState = (cart: Cart) => {
        if (isBrowser) {
            localStorage.setItem(localStorageCartIdKey, cart.id);
        }

        setCart(cart);
    };

    useEffect(() => {
        (async () => {
            const existingCartId = isBrowser ? localStorage.getItem(localStorageCartIdKey) : null;
            if (existingCartId && typeof existingCartId === `string`) {
                try {
                    const data = await cartGet(existingCartId);
                    if (data?.cart) setCartState(data.cart);
                    else {
                        setCart(null);
                        localStorage.removeItem(localStorageCartIdKey);
                    }
                } catch (err) {
                    console.log(err);
                    localStorage.removeItem(localStorageCartIdKey);
                }
            }
        })();
    }, []);

    const cartItemsAdd = async (lines: CartLineInput[]) => {
        setLoading(true);

        let res = {} as ShopifyCartResponse;

        try {
            if (cart) {
                res = await cartLinesAdd(cart.id, lines);
            } else {
                res = await cartCreate({ lines });
            }
            if (res?.cart) {
                setCartState(res.cart);
                setAddedToCart(true);
                setTimeout(() => setAddedToCart(false), 1000);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    const cartItemsUpdate = async (lines: CartLineUpdateInput[]) => {
        setLoading(true);

        let res = {} as ShopifyCartResponse;

        try {
            if (cart) {
                res = await cartLinesUpdate(cart.id, lines);
                if (res?.cart) {
                    setCartState(res.cart);
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    const cartItemsRemove = async (lines: ID[]) => {
        setLoading(true);

        let res = {} as ShopifyCartResponse;

        try {
            if (cart) {
                res = await cartLinesRemove(cart.id, lines);
                if (res?.cart) {
                    setCartState(res.cart);
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    const cartAssociateBuyer = async (buyerIdentity: CartBuyerIdentityInput) => {
        setLoading(true);

        let res = {} as ShopifyCartResponse;

        try {
            if (cart) {
                res = await cartBuyerIdentityUpdate(cart.id, buyerIdentity);
                if (res?.cart) {
                    setCartState(res.cart);
                } else console.log('error', res);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    const cartDiscountUpdate = async (code: string, remove?: boolean) => {
        setLoading(true);

        let res = {} as ShopifyCartResponse;

        let codes =
            cart?.discountCodes
                .filter(discount => discount.applicable)
                .map(discount => discount.code) || [];

        if (codes.includes(code) && !remove) {
            setLoading(false);
            return res;
        }

        codes = [...codes, code];
        if (remove) codes = codes.filter(discountCode => discountCode !== code);

        try {
            if (cart) {
                res = await cartDiscountCodesUpdate(cart.id, codes);
                if (res?.cart) {
                    setCartState(res.cart);
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    return (
        <ShopifyContext.Provider
            value={{
                cartItemsAdd,
                cartItemsUpdate,
                cartItemsRemove,
                cartAssociateBuyer,
                cart,
                loading,
                addedToCart,
                cartDiscountUpdate,
            }}
        >
            {children}
        </ShopifyContext.Provider>
    );
};
