import { GraphQLClient, gql } from 'graphql-request';
import {
    Cart,
    CartBuyerIdentityInput,
    CartInput,
    CartLineInput,
    CartLineUpdateInput,
    Customer,
    CustomerAccessToken,
    CustomerAccessTokenCreateInput,
    CustomerCreateInput,
    CustomerUpdateInput,
    ID,
} from 'shopify-buy';
import { localStorageCustomerTokenKey } from './localTokenKeys';

export const shopifyClient = new GraphQLClient(
    'https://shop.smartazminerals.com/api/2023-07/graphql.json',
    {
        headers: {
            'X-Shopify-Storefront-Access-Token': 'a55fab384ac3482c6112bd6622a38501',
        },
    }
);

function saveToken(token: string) {
    localStorage.setItem(localStorageCustomerTokenKey, token);
}

type CustomerTokenGetType =
    | {
          accessToken: string;
          expiresAt: string;
      }
    | undefined;

function customerTokenGet(): CustomerTokenGetType {
    const token = localStorage.getItem(localStorageCustomerTokenKey);
    if (token) {
        return JSON.parse(token);
    } else return undefined;
}

function customerTokenDelete() {
    const token = customerTokenGet();
    if (token?.accessToken) {
        try {
            shopifyClient.request(
                gql`
                    mutation customerAccessTokenDelete($customerAccessToken: String!) {
                        customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                            userErrors {
                                message
                            }
                        }
                    }
                `,
                { customerAccessToken: token.accessToken }
            );
            localStorage.removeItem(localStorageCustomerTokenKey);
        } catch (err) {
            console.log(err);
        }
    }
}

function handleFetchError(e: any) {
    if (e?.response?.errors?.length > 0) {
        return {
            error: e?.response?.errors[0]?.message,
        };
    }
    return {
        error: 'Unknown error, please try again later.',
    };
}

function removeEmptyObjectValues(object: {
    [index: string]: string | object | boolean | number | undefined;
}) {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            if (value === null || value === undefined || value === '' || value === '+') {
                delete object[key];
            }
        }
    }
}

type CustomerGetType = {
    customer: null | Customer;
};

function customerGet(customerAccessToken: string): Promise<CustomerGetType> {
    return shopifyClient.request(
        gql`
            query ($customerAccessToken: String!) {
                customer(customerAccessToken: $customerAccessToken) {
                    id
                    firstName
                    lastName
                    phone
                    email
                    numberOfOrders
                    defaultAddress {
                        address1
                        address2
                        zip
                        city
                        province
                        country
                        countryCodeV2
                    }
                    orders(first: 30) {
                        nodes {
                            processedAt
                            orderNumber
                            fulfillmentStatus
                            financialStatus
                            totalPrice {
                                amount
                            }
                            originalTotalPrice {
                                amount
                            }
                            totalShippingPrice {
                                amount
                            }
                            lineItems(first: 30) {
                                nodes {
                                    quantity
                                    title
                                    variant {
                                        id
                                        price {
                                            amount
                                        }
                                        product {
                                            handle
                                        }
                                        image {
                                            altText
                                            height
                                            id
                                            url
                                            width
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        { customerAccessToken }
    );
}

type CustomerCreateType = {
    error?: string;
    email?: string;
};

async function customerCreate(input: CustomerCreateInput): Promise<CustomerCreateType> {
    type Response = {
        customerCreate: {
            customer: {
                email: string;
            };
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation customerCreate($input: CustomerCreateInput!) {
                    customerCreate(input: $input) {
                        customer {
                            email
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { input }
        );
        if (res.customerCreate.customerUserErrors.length > 0) {
            return {
                error: res.customerCreate.customerUserErrors[0].message,
            };
        } else return res.customerCreate.customer;
    } catch (e) {
        return handleFetchError(e);
    }
}

type CustomerRecoverType = {
    error?: string;
    success?: boolean;
};

async function customerRecover(email: string): Promise<CustomerRecoverType> {
    type Response = {
        customerRecover: {
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation customerRecover($email: String!) {
                    customerRecover(email: $email) {
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { email }
        );
        if (res.customerRecover.customerUserErrors.length > 0) {
            return {
                error: res.customerRecover.customerUserErrors[0].message,
            };
        } else
            return {
                success: true,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

type CustomerResetByUrlType = {
    error?: string;
    accessToken?: string;
    expiresAt?: string;
};

async function customerResetByUrl(
    password: string,
    resetUrl: string
): Promise<CustomerResetByUrlType> {
    type Response = {
        customerResetByUrl: {
            customerAccessToken: CustomerAccessToken;
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
                    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { password, resetUrl }
        );
        if (res.customerResetByUrl.customerUserErrors.length > 0) {
            return {
                error: res.customerResetByUrl.customerUserErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerResetByUrl.customerAccessToken));
            return res.customerResetByUrl.customerAccessToken;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

async function customerUpdate(customer: CustomerUpdateInput, customerAccessToken: string) {
    type Response = {
        customerUpdate: {
            customer: Customer;
            customerAccessToken: CustomerAccessToken;
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation customerUpdate(
                    $customer: CustomerUpdateInput!
                    $customerAccessToken: String!
                ) {
                    customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
                        customer {
                            id
                            firstName
                            lastName
                            phone
                            defaultAddress {
                                address1
                                address2
                                zip
                                city
                                province
                                country
                            }
                        }
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { customer, customerAccessToken }
        );
        if (res.customerUpdate.customerUserErrors.length > 0) {
            return {
                error: res.customerUpdate.customerUserErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerUpdate.customerAccessToken));
            return {
                customer: res.customerUpdate.customer,
                customerAccessToken: res.customerUpdate.customerAccessToken,
            };
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

type CustomerAccessTokenCreateType = {
    error?: string;
    accessToken?: string;
    expiresAt?: string;
};

async function customerAccessTokenCreate(
    input: CustomerAccessTokenCreateInput
): Promise<CustomerAccessTokenCreateType> {
    type Response = {
        customerAccessTokenCreate: {
            customerAccessToken: CustomerAccessToken;
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                    customerAccessTokenCreate(input: $input) {
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { input }
        );
        if (res.customerAccessTokenCreate.customerUserErrors.length > 0) {
            return {
                error: res.customerAccessTokenCreate.customerUserErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerAccessTokenCreate.customerAccessToken));
            return res.customerAccessTokenCreate.customerAccessToken;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

async function customerAccessTokenRenew(customerAccessToken: string) {
    type Response = {
        customerAccessTokenRenew: {
            customerAccessToken: CustomerAccessToken;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation customerAccessTokenRenew($customerAccessToken: String!) {
                    customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        userErrors {
                            message
                        }
                    }
                }
            `,
            { customerAccessToken }
        );
        if (res.customerAccessTokenRenew.userErrors.length > 0) {
            return {
                error: res.customerAccessTokenRenew.userErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerAccessTokenRenew.customerAccessToken));

            return res.customerAccessTokenRenew.customerAccessToken;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

const CART_FIELDS = gql`
    fragment cartFields on Cart {
        id
        totalQuantity
        buyerIdentity {
            countryCode
            email
            phone
            customer {
                firstName
                lastName
                email
                phone
                defaultAddress {
                    address1
                    address2
                    city
                    country
                    countryCodeV2
                    firstName
                    lastName
                    province
                    zip
                    phone
                }
            }
            deliveryAddressPreferences {
                ... on MailingAddress {
                    address1
                    address2
                    city
                    country
                    countryCodeV2
                    firstName
                    lastName
                    province
                    zip
                    phone
                }
            }
        }
        checkoutUrl
        cost {
            checkoutChargeAmount {
                amount
                currencyCode
            }
        }
        discountAllocations {
            discountedAmount {
                amount
                currencyCode
            }
        }
        discountCodes {
            applicable
            code
        }
        lines(first: 100) {
            nodes {
                id
                quantity
                discountAllocations {
                    discountedAmount {
                        amount
                        currencyCode
                    }
                }
                cost {
                    amountPerQuantity {
                        amount
                        currencyCode
                    }
                }
                sellingPlanAllocation {
                    sellingPlan {
                        id
                        name
                    }
                }
                merchandise {
                    ... on ProductVariant {
                        id
                        product {
                            title
                            handle
                            featuredImage {
                                altText
                                height
                                width
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

function cartGet(id: string): Promise<{ cart: Cart }> {
    return shopifyClient.request(
        gql`
            query ($id: ID!) {
                cart(id: $id) {
                    ...cartFields
                }
            }
            ${CART_FIELDS}
        `,
        { id }
    );
}

export type ShopifyCartResponse = {
    cart?: Cart;
    error?: string;
};

async function cartCreate(input: CartInput): Promise<ShopifyCartResponse> {
    type Response = {
        cartCreate: {
            cart: Cart;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation cartCreate($input: CartInput) {
                    cartCreate(input: $input) {
                        cart {
                            ...cartFields
                        }
                        userErrors {
                            message
                        }
                    }
                }
                ${CART_FIELDS}
            `,
            { input }
        );
        if (res.cartCreate.userErrors.length > 0) {
            return {
                error: res.cartCreate.userErrors[0].message,
            };
        } else
            return {
                cart: res.cartCreate.cart,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

async function cartLinesAdd(cartId: string, lines: CartLineInput[]): Promise<ShopifyCartResponse> {
    type Response = {
        cartLinesAdd: {
            cart: Cart;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
                    cartLinesAdd(cartId: $cartId, lines: $lines) {
                        cart {
                            ...cartFields
                        }
                        userErrors {
                            message
                        }
                    }
                }
                ${CART_FIELDS}
            `,
            { cartId, lines }
        );
        if (res.cartLinesAdd.userErrors.length > 0) {
            return {
                error: res.cartLinesAdd.userErrors[0].message,
            };
        } else
            return {
                cart: res.cartLinesAdd.cart,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

async function cartLinesUpdate(
    cartId: string,
    lines: CartLineUpdateInput[]
): Promise<ShopifyCartResponse> {
    type Response = {
        cartLinesUpdate: {
            cart: Cart;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                    cartLinesUpdate(cartId: $cartId, lines: $lines) {
                        cart {
                            ...cartFields
                        }
                        userErrors {
                            message
                        }
                    }
                }
                ${CART_FIELDS}
            `,
            { cartId, lines }
        );
        if (res.cartLinesUpdate.userErrors.length > 0) {
            return {
                error: res.cartLinesUpdate.userErrors[0].message,
            };
        } else
            return {
                cart: res.cartLinesUpdate.cart,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

async function cartLinesRemove(cartId: string, lineIds: ID[]): Promise<ShopifyCartResponse> {
    type Response = {
        cartLinesRemove: {
            cart: Cart;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                        cart {
                            ...cartFields
                        }
                        userErrors {
                            message
                        }
                    }
                }
                ${CART_FIELDS}
            `,
            { cartId, lineIds }
        );
        if (res.cartLinesRemove.userErrors.length > 0) {
            return {
                error: res.cartLinesRemove.userErrors[0].message,
            };
        } else
            return {
                cart: res.cartLinesRemove.cart,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

async function cartDiscountCodesUpdate(
    cartId: string,
    discountCodes: string[]
): Promise<ShopifyCartResponse> {
    type Response = {
        cartDiscountCodesUpdate: {
            cart: Cart;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
                    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
                        cart {
                            ...cartFields
                        }
                        userErrors {
                            message
                        }
                    }
                }
                ${CART_FIELDS}
            `,
            { cartId, discountCodes }
        );
        if (res.cartDiscountCodesUpdate.userErrors.length > 0) {
            return {
                error: res.cartDiscountCodesUpdate.userErrors[0].message,
            };
        } else
            return {
                cart: res.cartDiscountCodesUpdate.cart,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

async function cartBuyerIdentityUpdate(
    cartId: string,
    buyerIdentity: CartBuyerIdentityInput
): Promise<ShopifyCartResponse> {
    type Response = {
        cartBuyerIdentityUpdate: {
            cart: Cart;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await shopifyClient.request(
            gql`
                mutation cartBuyerIdentityUpdate(
                    $buyerIdentity: CartBuyerIdentityInput!
                    $cartId: ID!
                ) {
                    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
                        cart {
                            ...cartFields
                        }
                        userErrors {
                            message
                        }
                    }
                }
                ${CART_FIELDS}
            `,
            { cartId, buyerIdentity }
        );
        if (res.cartBuyerIdentityUpdate.userErrors.length > 0) {
            return {
                error: res.cartBuyerIdentityUpdate.userErrors[0].message,
            };
        } else
            return {
                cart: res.cartBuyerIdentityUpdate.cart,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

async function productAvailability(handle: string) {
    try {
        type Response = {
            product: null | {
                availableForSale: boolean;
            };
        };

        const res: Response = await shopifyClient.request(
            gql`
                query product($handle: String!) {
                    product(handle: $handle) {
                        availableForSale
                    }
                }
            `,
            { handle }
        );
        if (res.product) {
            if (res.product?.availableForSale) return true;
            else return false;
        }
    } catch (e) {
        return 'error';
    }
}

export {
    cartBuyerIdentityUpdate,
    cartCreate,
    cartDiscountCodesUpdate,
    cartGet,
    cartLinesAdd,
    cartLinesRemove,
    cartLinesUpdate,
    customerAccessTokenCreate,
    customerAccessTokenRenew,
    customerCreate,
    customerGet,
    customerRecover,
    customerResetByUrl,
    customerTokenDelete,
    customerTokenGet,
    customerUpdate,
    productAvailability,
    removeEmptyObjectValues,
};
