import { Input, Label } from '@/FormComponents';
import { PlainBtn, SecondaryBtn } from '@/buttons';
import { smTxt } from '@/layout/styles/Typography';
import { flexCenter } from '@/layout/styles/classes';
import { ReactComponent as DeleteIcon } from '@a/icons/cross.svg';
import { ReactComponent as DiscountIcon } from '@a/icons/discount.svg';
import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useContext, useEffect, useState } from 'react';
import { localStorageCartCodeKey } from '../localTokenKeys';

const Box = styled.div`
    margin-top: 32px;
    position: relative;

    > form:first-of-type {
        ${flexCenter};
        flex-wrap: wrap;
        gap: 16px;
        max-width: 550px;

        input {
            border-color: ${({ theme }) => theme.colors.gray5};
            margin: 0;

            :hover {
                border-color: ${({ theme }) => theme.colors.blue4};
            }

            :focus-visible {
                border-color: ${({ theme }) => theme.colors.blue4};
            }
        }

        button {
            padding: 12px 24px;
        }
    }

    @media (min-width: 350px) {
        > form:first-of-type {
            flex-wrap: nowrap;
        }
    }
`;

const Error = styled.p`
    ${smTxt};
    margin: 8px 0 0;
    color: #ff0033;
    position: absolute;
    left: 0;
    top: -32px;
`;

const Success = styled.div`
    margin-top: 8px;

    > div {
        ${flexCenter};
        justify-content: flex-start;
        gap: 12px;
        flex-wrap: wrap;

        > p {
            ${smTxt};
            display: inline-flex;
            align-items: center;
            margin: 0;
            line-height: 150%;
            justify-content: flex-start;
            text-transform: uppercase;
            background-color: #ededed;
            border-radius: 5px;
            padding: 2px 5px;

            > svg {
                margin-right: 4px;
            }
        }
    }

    > p {
        margin: 12px 0 0;

        > span {
            color: ${({ theme }) => theme.colors.blue4};
            margin-left: 0.5ch;
        }
    }
`;

const RemoveBtn = styled(PlainBtn)`
    padding-left: 12px;

    > svg {
        width: 13px;
        height: 12.3px;

        > path {
            transition: fill 0.3s ease-in-out;
        }
    }

    :hover {
        > svg > path {
            fill: ${({ theme }) => theme.colors.blue4};
        }
    }

    :focus-visible {
        > svg > path {
            fill: ${({ theme }) => theme.colors.blue4};
        }
    }
`;

export const CartDiscount = () => {
    const [error, setError] = useState<'' | 'exist' | 'invalid'>('');
    const { cart, cartDiscountUpdate, loading } = useContext(ShopifyContext);

    useEffect(() => {
        const code = localStorage.getItem(localStorageCartCodeKey);

        if (typeof code === 'string' && cart) {
            cartDiscountUpdate(code);
            localStorage.removeItem(localStorageCartCodeKey);
        }
    }, [cartDiscountUpdate, cart]);

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.currentTarget));
        const code = data.discountCode;

        const exist = cart?.discountCodes.filter(
            discount => discount.code === code && discount.applicable
        );
        if (exist?.length) {
            setError('exist');
            setTimeout(() => setError(''), 2000);
        } else {
            const res = await cartDiscountUpdate(code as string);
            const codeResponse = res.cart?.discountCodes.filter(discount => code === discount.code);
            if (codeResponse && !codeResponse[0].applicable) {
                setError('invalid');
                setTimeout(() => setError(''), 2000);
            }
        }
        e.target.reset();
    };

    const handleRemove = (v: string) => () => {
        cartDiscountUpdate(v, true);
    };

    // total cart discount.
    const discount =
        cart?.discountAllocations.reduce((acc, cv) => {
            return acc + Number(cv.discountedAmount.amount);
        }, 0) || 0;

    const isDiscountApplied =
        cart?.discountCodes.some(discount => discount.applicable) || cart?.discountAllocations;

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Label htmlFor="discountCode">Enter a discount code if you have one</Label>
                <Input
                    type="text"
                    name="discountCode"
                    id="discountCode"
                    required
                    placeholder="Discount code"
                />
                <SecondaryBtn type="submit" disabled={loading}>
                    Apply
                </SecondaryBtn>
            </form>
            {error ? (
                <Error>
                    {error === 'exist'
                        ? 'Discount code already applied.'
                        : 'Enter a valid discount code.'}
                </Error>
            ) : (
                ''
            )}
            {isDiscountApplied ? (
                <Success>
                    {cart?.discountCodes ? (
                        <div>
                            {cart?.discountCodes.map((discount, i) =>
                                discount.applicable ? (
                                    <p key={i}>
                                        <DiscountIcon /> {discount.code}
                                        <RemoveBtn
                                            onClick={handleRemove(discount.code)}
                                            aria-label="remove this discount code"
                                        >
                                            <DeleteIcon />
                                        </RemoveBtn>
                                    </p>
                                ) : (
                                    ''
                                )
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    {cart?.discountAllocations.length ? (
                        <p>
                            Cart Discount:{' '}
                            <span>
                                &#8208;{' '}
                                {getSymbolFromCurrency(
                                    cart?.discountAllocations[0].discountedAmount.currencyCode
                                )}{' '}
                                {discount.toFixed(2)}
                            </span>
                        </p>
                    ) : (
                        ''
                    )}
                </Success>
            ) : (
                ''
            )}
        </Box>
    );
};
