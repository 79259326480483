import { css, Global, Theme } from '@emotion/react';
import '@fontsource/jost';
import '@fontsource/jost/500.css';
import '@fontsource/jost/600.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4, tagline } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#FFFFFF',
        gray1: '#F2F2F2',
        gray2: '#D9D9D9',
        gray3: '#727272',
        gray4: '#5C5C5C',
        gray5: '#454545',
        blue1: '#E2F5FB',
        blue2: '#BCE7F5',
        blue3: '#A0DCF1',
        blue4: '#41BAE3',
        blue5: '#3495B6',
        blue6: '#205D72',
        blue7: '#17414F',
        blue8: '#2D3F59',
        yellow1: '#FFF6DF',
        yellow2: '#FFCD55',
        yellow3: '#FFCC00',
        yellow4: '#FFC12B',
        yellow5: '#59440F',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.gray3};
                    font-family: 'Jost', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 150%;
                    letter-spacing: 0.005em;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray3};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                .tagline {
                    ${tagline};
                }

                button {
                    :disabled {
                        pointer-events: none;
                        opacity: 0.7;
                    }
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }
            `}
        />
    );
};
