import { PopularProducts } from '@/PopularProducts';
import { Footer } from '@/layout/Footer';
import { Nav } from '@/layout/Nav';
import { Newsletter } from '@/layout/Newsletter';
import { SkipNavContent, SkipNavLink } from '@/layout/SkipNav';
import { GlobalStyles, theme } from '@/layout/styles/GlobalStyles';
import { CartToast } from '@/shopify-client/cart/CartToast';
import { ThemeProvider } from '@emotion/react';
import { ShopifyProvider } from '@s/context/ShopifyContext';
import { ReactNode } from 'react';

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
    return (
        <ThemeProvider theme={theme}>
            <ShopifyProvider>
                <GlobalStyles />
                <SkipNavLink />
                <Nav />
                <CartToast />
                <SkipNavContent />
                <main>{children}</main>
                <PopularProducts />
                <Newsletter />
                <Footer />
            </ShopifyProvider>
        </ThemeProvider>
    );
};
