import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Children, Fragment, ReactNode } from 'react';
import { flexSpace } from './layout/styles/classes';

export const Form = styled.form<{ submit?: boolean; gray?: boolean }>`
    padding: 32px 16px;
    position: relative;
    width: 100%;
    max-width: 736px;
    scroll-margin-top: 120px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 16px;

    > h2 {
        color: ${({ theme }) => theme.colors.blue8};
        margin: 0 0 32px;
        text-align: center;
    }

    > button:last-of-type {
        display: block;
        max-width: max-content;
        margin: 32px auto 0;
    }

    @media (min-width: 768px) {
        padding: 32px;
    }

    @media (min-width: 1024px) {
        border-radius: 32px;
        padding: 64px 48px;

        > h2 {
            margin-bottom: 48px;
        }

        > button:last-of-type {
            margin-top: 48px;
        }
    }

    ${({ submit }) =>
        submit &&
        css`
            select,
            textarea,
            input,
            > button {
                pointer-events: none;
                visibility: hidden;
            }
        `};
`;

export const Input = styled.input<{ rows?: number }>`
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray5};
    padding: 12px 16px;
    border-radius: 16px;
    font-size: 1rem;
    display: block;
    outline: none;
    margin-bottom: 16px;
    width: 100%;
    transition: border-color 0.3s ease-in-out;
    resize: none;

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray3};
    }

    :hover {
        border-color: ${({ theme }) => theme.colors.blue7};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.blue7};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.gray5};
        color: ${({ theme }) => theme.colors.gray5};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: border-color 0.3s ease-in-out, background-color 50000s ease-in-out 0s;
    }

    :not(:placeholder-shown):invalid {
        border-color: #ff0033;
    }

    @media (min-width: 1280px) {
        margin-bottom: 32px;
    }
`;

export const Label = styled.label`
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0;
`;

export const InputFlex = styled.div`
    @media (min-width: 600px) {
        ${flexSpace};

        > div {
            width: calc(50% - 10px);
        }
    }

    @media (min-width: 1024px) {
        > div {
            width: calc(50% - 20px);
        }
    }
`;

export const CheckBoxes = styled.div`
    > p {
        margin: 0 0 8px;

        :hover {
            color: ${({ theme }) => theme.colors.blue7};
        }
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: max-content;

        > label {
            text-transform: capitalize;
            margin-left: 10px;
            transition: color 0.3s ease-in-out;
            font-size: 1rem;

            :hover {
                color: ${({ theme }) => theme.colors.blue7};
            }
        }

        > input {
            width: 16px;
            height: 16px;
            accent-color: ${({ theme }) => theme.colors.blue6};
            transition: accent-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

            :hover {
                box-shadow: inset 0 0 0 50px ${({ theme }) => theme.colors.blue6};
            }

            :focus-visible {
                box-shadow: inset 0 0 0 50px ${({ theme }) => theme.colors.blue6};
            }

            :checked {
                box-shadow: none;
            }
        }
    }
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: inherit;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: #f5f5f5;

    > h2 {
        margin-top: 0;
    }

    > p {
        font-size: 1.25rem;
        margin: 0;
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }
`;

export const Thanks = ({ submit, children }: { submit: boolean; children?: ReactNode }) => (
    <StyledThanks submit={submit}>
        {Children.count(children) ? (
            children
        ) : (
            <Fragment>
                <h2>Thank you</h2>
                <p>Someone will be right with you shortly!</p>
            </Fragment>
        )}
    </StyledThanks>
);
