import { CLink } from '@/CLink';
import { ArrowCircleBtn, TxtBtn } from '@/buttons';
import { ShopifyContext } from '@s/context/ShopifyContext';
import { useContext, useEffect, useState } from 'react';

export const AddToCartSingle = ({ merchandiseId }: { merchandiseId: string }) => {
    const { cart, cartItemsAdd, loading } = useContext(ShopifyContext);
    const [inCart, setInCart] = useState(false);

    const handleAdd = () => {
        cartItemsAdd([
            {
                merchandiseId,
                quantity: 1,
            },
        ]);
    };

    useEffect(() => {
        const cartItem =
            cart && cart.lines?.nodes.length > 0
                ? cart.lines?.nodes.filter(item => item?.merchandise?.id === merchandiseId)
                : [];

        setInCart(cartItem.length > 0);
    }, [cart, merchandiseId]);

    return inCart ? (
        <TxtBtn as={CLink} to="/cart/">
            View Cart
        </TxtBtn>
    ) : (
        <ArrowCircleBtn onClick={handleAdd} disabled={loading}>
            add to cart
        </ArrowCircleBtn>
    );
};
