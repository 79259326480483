import { CLink } from '@/CLink';
import { AddToCartSingle } from '@/shopify-client/AddToCartSingle';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { lgTxt } from './layout/styles/Typography';
import { flexSpace, flexStart } from './layout/styles/classes';

const Card = styled.div`
    ${flexStart};
    flex-direction: column;
    flex-shrink: 0;
    width: min(100%, 345px);

    > a {
        :first-of-type {
            > .gatsby-image-wrapper {
                background-color: ${({ theme }) => theme.colors.yellow1};
                border-radius: 16px;
                opacity: 0.9999; //safari overflow bug fix

                img {
                    transition: transform 0.3s ease-in-out;
                }
            }

            :hover,
            :focus {
                > .gatsby-image-wrapper img {
                    transform: scale(1.1);
                }
            }
        }
        :last-of-type {
            ${lgTxt};
            color: ${({ theme }) => theme.colors.blue7};
            font-weight: 500;
            margin: 14px 0;
            transition: color 0.3s ease-in-out;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.blue5};
            }
        }
    }

    > div:last-of-type {
        ${flexSpace};
        margin-top: auto;
        align-self: stretch;
        min-height: 42px;

        > p {
            ${lgTxt};
            color: ${({ theme }) => theme.colors.blue4};
            margin: 0;
        }
    }
`;

const Placeholder = styled.div`
    background-color: ${({ theme }) => theme.colors.yellow1};
    width: 345px;
    height: 450px;
`;

export type ProductDataType = {
    title: string;
    handle: string;
    storefrontId?: string;
    variants: {
        storefrontId: string;
        price: number;
    }[];
    featuredImage: {
        localFile: {
            childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
            };
        };
    };
};

export const ProductCard = ({ title, handle, featuredImage, variants }: ProductDataType) => {
    return (
        <Card>
            {featuredImage ? (
                <CLink to={`/products/${handle}/`}>
                    <GatsbyImage
                        image={featuredImage?.localFile?.childImageSharp.gatsbyImageData}
                        alt={title}
                    />
                </CLink>
            ) : (
                <Placeholder />
            )}
            <CLink to={`/products/${handle}/`}>{title}</CLink>
            <div>
                <AddToCartSingle merchandiseId={variants[0]?.storefrontId} />
                <p>${variants[0]?.price}</p>
            </div>
        </Card>
    );
};
