import { Fragment, useEffect, useState } from 'react';

type Props = {
    merchandiseId: string;
    price: string | number;
    quantity: number;
};

export const ShopPayBtn = ({ merchandiseId, price, quantity }: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const termPrice = (Number(price) / 4).toFixed(2);
    const id = merchandiseId.split('gid://shopify/ProductVariant/')[1];

    useEffect(() => {
        const id = 'shop-pay-info-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute(
                'src',
                'https://cdn.shopify.com/shopifycloud/shop-js/v1.0/client.js'
            );
            script.setAttribute('id', id);
            document.body.appendChild(script);
            script.onload = () => {
                const btn = document.querySelector('.shop-pay-btn-product-page');
                const link = btn && btn?.shadowRoot?.getElementById('shop-pay-button-link');
                link?.setAttribute('target', '_blank');
            };
        }
    }, []);

    // shopify modal in smart pack product shows installment plans
    // it has a button with link with current domain which is incorrect
    // it should be shop.smartazminerals.com
    useEffect(() => {
        if (modalOpen) {
            const modal = document.getElementsByTagName('shopify-installments-sample-plans-modal');
            if (modal.length) {
                const btns = modal[0].querySelector('.navigation-buttons');
                const btn = btns?.getElementsByTagName('shop-pay-button');
                const link = btn && btn[0]?.shadowRoot?.getElementById('shop-pay-button-link');
                link?.setAttribute(
                    'href',
                    link?.href?.replace('smartazminerals.com', 'shop.smartazminerals.com')
                );
                link?.setAttribute('target', '_blank');
            }
        }
    }, [modalOpen]);

    return (
        <Fragment>
            <shop-pay-button
                store-url="https://shop.smartazminerals.com"
                variants={`${id}:${quantity}`}
                class="shop-pay-btn-product-page"
            ></shop-pay-button>
            <shopify-payment-terms
                onClick={e => {
                    setModalOpen(true);
                }}
                variant-id={id}
                shopify-meta={`{"type":"product","variants":[{"id":${id},"price_per_term":"$${termPrice}","full_price":"$${price}","eligible":true,"available":true}],"min_price":"$50.00","max_price":"$30,000.00","financing_plans":[{"min_price":"$50.00","max_price":"$149.99","terms":[{"apr":0,"loan_type":"split_pay","installments_count":4}]},{"min_price":"$150.00","max_price":"$999.99","terms":[{"apr":0,"loan_type":"split_pay","installments_count":4},{"apr":15,"loan_type":"interest","installments_count":6},{"apr":15,"loan_type":"interest","installments_count":12}]},{"min_price":"$1,000.00","max_price":"$30,000.00","terms":[{"apr":15,"loan_type":"interest","installments_count":3},{"apr":15,"loan_type":"interest","installments_count":6},{"apr":15,"loan_type":"interest","installments_count":12}]}],"installments_buyer_prequalification_enabled":false}`}
            ></shopify-payment-terms>
        </Fragment>
    );
};
