import { DialogClose } from '@/DialogPrimitive';
import { PlainBtn, SecondaryBtn } from '@/buttons';
import { lgTxt } from '@/layout/styles/Typography';
import { flexCenter } from '@/layout/styles/classes';
import { ReactComponent as CartIcon } from '@a/icons/cart.svg';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as PillsIcon } from '@a/icons/pills.svg';
import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Fragment, useContext, useState } from 'react';
import { customerGet, customerTokenGet, removeEmptyObjectValues } from '..';
import { CartDiscount } from './CartDiscount';
import { CartItem } from './CartItem';

const Info = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    height: 100%;

    //cart empty text
    > p {
        ${lgTxt};
        font-weight: 500;
        margin: 0;
        text-align: center;
    }
`;

const Top = styled.div`
    margin-bottom: 32px;

    > button {
        margin-left: auto;
        display: block;
    }

    > p {
        ${flexCenter};
        margin: 0;

        > svg {
            margin-right: 7px;
        }
    }
    > h2 {
        color: ${({ theme }) => theme.colors.blue8};
        margin: 4px 0 0;
        text-align: center;
    }
`;

const ItemsDisplay = styled.div`
    overflow-y: auto;
    padding-right: 12px;
`;

const Cta = styled.div<{ cartPage?: boolean }>`
    ${flexCenter};
    margin-top: 32px;
    flex-direction: column;

    > p {
        ${lgTxt};
        margin: 0 0 8px;
        margin-bottom: ${({ cartPage }) => (cartPage ? '32px' : '8px')};

        span {
            color: ${({ theme }) => theme.colors.blue4};
        }
    }

    > button {
        > svg {
            margin-right: 7px;
        }
    }
`;

export const CartContent = ({ cartPage }: { cartPage?: boolean }) => {
    const { cart, cartAssociateBuyer, loading } = useContext(ShopifyContext);
    const [fetching, setFetching] = useState(false);

    const emptyCart = cart?.lines?.nodes.length === 0 || !cart;

    const handleCheckout = async () => {
        setFetching(true);
        const token = customerTokenGet();
        if (token?.accessToken) {
            const data = await customerGet(token.accessToken);
            if (data.customer && data.customer.defaultAddress) {
                const address = {
                    ...data.customer.defaultAddress,
                };
                delete address.countryCodeV2;

                const buyer = {
                    countryCode: data.customer.defaultAddress.countryCodeV2,
                    customerAccessToken: token.accessToken,
                    email: data.customer.email,
                    phone: data.customer.phone,
                    deliveryAddressPreferences: {
                        deliveryAddress: address,
                    },
                };
                removeEmptyObjectValues(buyer);
                await cartAssociateBuyer(buyer);
            }
        }
        setFetching(false);

        // manually add multiple discount codes since checkout url only shows one shopify BUG
        if (cart?.discountCodes && cart.discountCodes.length > 1) {
            const codes = cart.discountCodes.map(discount => discount.code);
            window.open(`${cart?.checkoutUrl}?discount=${codes.join(',')}`, '_self');
        } else window.open(cart?.checkoutUrl, '_self');
    };

    return (
        <Info>
            <Top>
                {cartPage ? (
                    ''
                ) : (
                    <DialogClose>
                        <PlainBtn aria-label="close cart dialog">
                            <CloseIcon width={36} height={36} />
                        </PlainBtn>
                    </DialogClose>
                )}
                <p className="tagline">
                    <PillsIcon /> checkout
                </p>
                <h2>Your Cart</h2>
            </Top>

            {emptyCart ? (
                <p>Your cart is empty</p>
            ) : (
                <Fragment>
                    <ItemsDisplay>
                        {cart?.lines?.nodes.map(item => (
                            <CartItem key={item.id} item={item} cartPage={cartPage} />
                        ))}
                    </ItemsDisplay>
                    <CartDiscount />
                    <Cta cartPage={cartPage}>
                        <p>
                            Subtotal:{' '}
                            <span>
                                {getSymbolFromCurrency(
                                    cart?.cost?.checkoutChargeAmount.currencyCode
                                )}{' '}
                                {cart?.cost?.checkoutChargeAmount.amount}
                            </span>
                        </p>
                        <SecondaryBtn onClick={handleCheckout} disabled={loading || fetching}>
                            <CartIcon /> Checkout
                        </SecondaryBtn>
                    </Cta>
                </Fragment>
            )}
        </Info>
    );
};
